import { createStore } from "redux";
import reducer from "./reducers/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
const composeEnhancers = composeWithDevTools({
  realtime: true,
  port: 8000,
  hostname: "192.168.0.103", //add your computer's IP
});
const store = createStore(reducer);
export default store;
