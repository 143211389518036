import { ADD_ONE, MINUS_ONE } from "../actions/actions";
const initialState = {
  number: {},
};
function reducer(state = initialState, action) {
  //console.log("redux state", state, action.payload);
  switch (action.type) {
    case ADD_ONE:
      return {
        number: action.payload,
      };
    case MINUS_ONE:
      console.log("actionpayload ", action.payload);
      let objectToDelete = state.number.find(
        (obj) => obj.id === action.payload.id
      );
      console.log("objectToDelete", objectToDelete);
      let newArray = [];
      state.number.map((e) => {
        console.log("e", e, e.id !== objectToDelete.id);
        if (
          objectToDelete !== undefined &&
          objectToDelete !== null &&
          (e.id !== objectToDelete.id || e.order !== objectToDelete.order)
        ) {
          newArray.push(e);
        }
      });
      if (objectToDelete === undefined || objectToDelete === null) {
        newArray = state.number;
      }
      console.log("New Array after decrement", newArray, state.number);
      return {
        number: newArray,
      };
    default:
      return state;
  }
}
export default reducer;
