export const UploadActionEnum = {
  ONLYUPLOAD: 0,
  APKPARSE: 1,
  IMAGEUPLOAD: 2,
};

export const ModuleNameEnum = {
  DOCUMENT: "document",
};

export const PendingStatus = {
  PENDING: 0,
  COMPLETE: 1,
  REJECTED: 2,
  ARCHIVE: 3,
};

export const FIELDTYPE = {
  TEXTBOX: 0,
  CHECKBOX: 1,
  DROPBOX: 2,
  INITIAL: 3,
  SIGNATURE: 4,
};
