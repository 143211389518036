import React from "react";

export const EkycInfo = () => {
  return (
    <div className="row">
      <div
        className="page-header col-md-12"
        style={{ paddingBottom: "10px", borderBottom: "1px solid gray" }}
      >
        <h3 className="page-title" style={{ width: "100%" }}>
          eKYC Information
        </h3>
      </div>
      <div className="col-md-12 grid-margin stretch-card average-price-card">
        <div className="card">
          <div className="card-body">
            <div className="">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  textDecoration: "underline",
                }}
              >
                You can do eKYC in two ways:
              </p>
              <p>
                1. Visit BPSCA eKYC Website:{" "}
                <a href="https://ekyc.digitalsignature.com.bd/" target="_blank">
                  ekyc.digitalsignature.com.bd
                </a>
              </p>
              <p>
                2. Use eKYC Android App{" "}
                <a href="https://ecipher.co/ekyc.apk" target="_blank">
                  Download App
                </a>
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  textDecoration: "underline",
                }}
              >
                Requirements:
              </p>
              <p>1. Original NID Card</p>
              <p>2. Webcam if Web-eKYC option is used.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-6 grid-margin stretch-card average-price-card">
        <a
          href="https://ecipher.co/"
          target="_blank"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <div className="card text-white">
            <div className="card-body">
              <div className="d-flex justify-content-center pb-2 align-items-center">
                <h2 className="font-weight-semibold mb-0 text-center">
                  <i
                    className="mdi mdi-web"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Visit eKYC Website
                </h2>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="col-md-6 grid-margin stretch-card average-price-card">
        <a
          href="https://ecipher.co/ekyc.apk"
          target="_blank"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <div className="card text-white">
            <div className="card-body">
              <div className="d-flex justify-content-center pb-2 align-items-center">
                <h2 className="font-weight-semibold mb-0 text-center">
                  <i
                    className="mdi mdi-briefcase-download"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Download eKYC App
                </h2>
              </div>
            </div>
          </div>
        </a>
      </div> */}
    </div>
  );
};
