import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// const baseURL = "http://mdm.commlinkinfotech.com";
const baseurl = window.location.href.startsWith(
  "https://esign.digitalsignature.com.bd"
)
  ? "https://esign.digitalsignature.com.bd:3030"
  : window.location.href.startsWith("https://dms.cca.gov.bd")
  ? "https://dms.cca.gov.bd:3030"
  : "https://dms.cca.gov.bd:3030";
//: "https://esign.digitalsignature.com.bd:3030";
//"http://180.210.129.103:8080";
// const baseURL = "http://localhost:8080";
const oauthurl = "/oauth/token";

const manageaccount = "/manage-account";
const myprofile = manageaccount + "/my-profile";
const changepassword = manageaccount + "/change-password";
const mysignature = manageaccount + "/my-signature-list";

export const packagemanagement = "/package-management";
export const packagemanagementuserlist = packagemanagement + "/user-list";
export const packagemanagementorganizationpackage =
  packagemanagement + "/my-organization-package";
export const packagemanagementmypackage = packagemanagement + "/my-package";
export const packagemanagementbalancerequest =
  packagemanagement + "/balance-request";

export const storagePackageList = "/storage-package-list";
export const storageManagement = "/storage-package-management";
export const storageManagementMyOrgPackage =
  storageManagement + "/my-organization-package";
export const storageManagementPendingList = storageManagement + "/pending-list";
export const storageManagementMyOrgPackageDetailsList =
  storageManagementMyOrgPackage + "/detail-list";
export const storageManagementUserList = storageManagement + "/user-list";
export const storageManagementStoragePackageRequest =
  storageManagement + "/storage-package-request";
export const storageManagementMyPackage = storageManagement + "/my-package";

export const baseURL = baseurl;
export const oauthURL = baseurl + oauthurl;
export const CallBackURL =
  (window.location.href.startsWith("https://esign.digitalsignature.com.bd")
    ? "https://esign.digitalsignature.com.bd"
    : window.location.href.startsWith("https://dms.cca.gov.bd")
    ? "https://dms.cca.gov.bd"
    : "https://180.210.129.176") + "/statusupdate";

export function errorCompute(error) {
  let errorMessage = "";
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    switch (error.response.status) {
      case 500:
        errorMessage = "Internal Server Error.";
        break;
      case 503:
        errorMessage = "Service unavailable.";
        //tests["Service unavailable."] = statMessages === 503;
        break;
      case 502:
        errorMessage = "502 bad gateway.";
        //tests["502 bad gateway."] = statMessages === 502;
        break;
      case 599:
        errorMessage = "Network connect timeout error.";
        // tests["Network connect timeout error."] = statMessages === 599;
        break;
      case 408:
        errorMessage = "Request timedout.";
        // tests["Request timedout."] = statMessages === 408;
        break;
      case 400:
        errorMessage = error.response.data.error_description;
        break;
      default:
        errorMessage = "Unhandlled error code.";
        break;
      //tests["Sorry, we are out of" + responseCode.code];
    }

    return confirmAlert({
      title: "Error Message",
      message: <p className="mod-p"> {errorMessage} </p>,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(error.request);
    return confirmAlert({
      title: "Error Message",
      message: <p className="mod-p"> {error.request} </p>,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    return confirmAlert({
      title: "Error Message",
      message: <p className="mod-p"> {error.message} </p>,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  }
}
