import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Spinner from "../app/shared/Spinner";
import { EkycInfo } from "./user-pages/EkycInfo";
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
// const MyPackage = lazy(() => import("./dashboard/MyPackage"));
const AdminRegistration = lazy(() =>
  import("./registration/AdminRegistration")
);

const Error404 = lazy(() => import("./user-pages/Error404"));
const Error500 = lazy(() => import("./user-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Login2 = lazy(() => import("./user-pages/Login2"));
const Register = lazy(() => import("./user-pages/Register"));

const BlankPage = lazy(() => import("./user-pages/BlankPage"));
const NewSign = lazy(() => import("./signing/NewSign"));
const SigningStatus = lazy(() => import("./signing/SigningStatus"));
const SigningRequest = lazy(() => import("./signing/SigningRequest"));
const CompletedSign = lazy(() => import("./signing/CompletedSign"));
const ArchiveSign = lazy(() => import("./signing/ArchiveSign"));
const RejectedSign = lazy(() => import("./signing/RejectedSign"));
const StartSign = lazy(() => import("./signing/StartSign"));
const ActiveFiles = lazy(() => import("./signing/ActiveFiles"));
const StatusUpdate = lazy(() => import("./user-pages/StatusUpdate.js"));
const PendingRequest = lazy(() => import("./user-pages/PendingRequest.js"));
const PreviewDoc = lazy(() => import("./user-pages/PreviewDoc.js"));
const Cancelled = lazy(() => import("./user-pages/Cancelled.js"));
const Successfull = lazy(() => import("./user-pages/Succesfull.js"));
const Failed = lazy(() => import("./user-pages/Failed.js"));
const ManageSignature = lazy(() => import("./signing/ManageSignature"));
const UserList = lazy(() => import("./user-pages/UserList"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const ForgetPassword = lazy(() => import("./user-pages/ForgetPassword"));
const ResetPassword = lazy(() => import("./user-pages/ResetPassword"));
const MyProfile = lazy(() => import("./user-pages/MyProfile"));
const PackageList = lazy(() => import("./admin/PackageList"));
const AdminDashboard = lazy(() => import("./admin/AdminDashboard"));
const MyPackage = lazy(() => import("./admin/MyPackage"));

const PackageManage = lazy(() => import("./admin/PackageManage"));
const PackageManage2 = lazy(() => import("./admin/PackageManage2"));
const CompanyList = lazy(() => import("./admin/CompanyList"));
const PdfPrepare = lazy(() => import("./signing/PdfPrepare"));
const CompanyUserList = lazy(() => import("./admin/CompanyUserList"));
const NewCompany = lazy(() => import("./admin/NewCompany"));
const PackageDistribute = lazy(() => import("./admin/PackageDistribute"));
const NewUser = lazy(() => import("./admin/NewUser"));
const ImageCrop = lazy(() => import("./imagecrop/ImageCrop"));
const DynamicForm = lazy(() => import("./user-pages/DynamicForm"));
const NewSelfSign = lazy(() => import("./signing/NewSelfSign"));
const StartFollow = lazy(() => import("./form/StartFollow"));
const StartMulti = lazy(() => import("./form/StartMulti"));
const StartTempSign = lazy(() => import("./form/StartSign"));
const StoragePlanList = lazy(() =>
  import("./storage-management/StoragePlanList")
);
const NewStoragePlan = lazy(() =>
  import("./storage-management/NewStoragePlan")
);
const StorageManagement = lazy(() =>
  import("./storage-management/StorageManagement")
);
const MyStorage = lazy(() => import("./storage-management/MyStorage"));
const PurchaseHistory = lazy(() =>
  import("./storage-management/PurchaseHistory")
);
const PendingPurchase = lazy(() =>
  import("./storage-management/PendingPurchase")
);
const StorageShop1 = lazy(() => import("./storage-management/StorageShop1"));
const StorageShop = lazy(() => import("./storage-management/StorageShop"));
const StorageRequest = lazy(() => import("./system-admin/StorageRequest"));
const SystemDashboard = lazy(() => import("./system-admin/SystemDashboard"));

class AppRoutes extends Component {
  render() {
    //const loggedIn = localStorage.getItem("loggedIn");
    const AppsRoute = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Login2} />

          <Route path="/login2" component={Login} />
          <Route path="/login" component={Login2} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/change-password" component={ResetPassword} />
          <Route path="/imagecrop" component={ImageCrop} />
          <Route path="/dynaform" component={DynamicForm} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <Route exact path="/systemdashboard" component={AdminDashboard} />
          <Route exact path="/package-management2" component={PackageManage} />
          <Route exact path="/my-package" component={MyPackage} />
          <ProtectedRoute
            exact
            path="/admin/package-list"
            component={PackageList}
          />
          <ProtectedRoute
            exact
            path="/package-management"
            component={PackageManage2}
          />
          {/* <Route exact path="/package-list" component={PackageList} /> */}
          {/* <Route exact path="/admin/package-list" component={MyPackage} /> */}
          {/* <Route exact path="/admin/company-list" component={CompanyList} /> */}
          <ProtectedRoute
            exact
            path="/user-management"
            component={CompanyUserList}
          />
          <Route exact path="/company" component={NewCompany} />
          <ProtectedRoute exact path="/new-self-sign" component={NewSelfSign} />
          <ProtectedRoute
            exact
            path="/self-form-sign"
            component={StartFollow}
          />
          <ProtectedRoute
            exact
            path="/start-template-sign"
            component={StartTempSign}
          />
          <ProtectedRoute exact path="/new-form-sign" component={StartMulti} />
          <ProtectedRoute
            exact
            path="/storage-package-list"
            component={StoragePlanList}
          />
          <ProtectedRoute
            exact
            path="/new-storage-plan"
            component={NewStoragePlan}
          />
          <ProtectedRoute
            exact
            path="/package-distribute"
            component={PackageDistribute}
          />
          <ProtectedRoute
            exact
            path="/storage-management"
            component={StorageManagement}
          />
          <ProtectedRoute exact path="/my-storage" component={MyStorage} />
          <ProtectedRoute
            exact
            path="/purchase-history"
            component={PurchaseHistory}
          />
          <ProtectedRoute
            exact
            path="/pending-purchase"
            component={PendingPurchase}
          />
          <ProtectedRoute
            exact
            path="/purchase-storage2"
            component={StorageShop1}
          />
          <ProtectedRoute
            exact
            path="/availablestorage-package"
            component={StorageShop}
          />
          <ProtectedRoute
            exact
            path="/storage-balance-request"
            component={StorageRequest}
          />
          <ProtectedRoute
            exact
            path="/admin-registration"
            component={AdminRegistration}
          />
          <ProtectedRoute
            exact
            path="/system-dashboard"
            component={SystemDashboard}
          />
          <Route exact path="/register" component={Register} />
          <ProtectedRoute exact path="/new-sign" component={NewSign} />
          <ProtectedRoute path="/pending-sign-list" component={SigningStatus} />
          <ProtectedRoute
            exact
            path="/completed-sign-list"
            component={CompletedSign}
          />
          <ProtectedRoute
            exact
            path="/rejected-sign-list"
            component={RejectedSign}
          />
          <ProtectedRoute exact path="/my-documents" component={ArchiveSign} />
          <ProtectedRoute
            exact
            path="/my-signature"
            component={ManageSignature}
          />
          <ProtectedRoute
            exact
            path="/signing-active-list"
            component={ActiveFiles}
          />
          {/* <ProtectedRoute
            path="/signing/signing-request"
            component={SigningRequest}
          /> */}
          <ProtectedRoute
            exact
            path="/signing-start-sign"
            component={StartSign}
          />
          <ProtectedRoute exact path="/ekyc-info" component={EkycInfo} />
          <ProtectedRoute exact path="/users-list" component={UserList} />
          <ProtectedRoute exact path="/user" component={NewUser} />
          <ProtectedRoute path="/statusupdate" component={StatusUpdate} />
          <ProtectedRoute path="/pending" component={PendingRequest} />
          <ProtectedRoute path="/preview" component={PreviewDoc} />
          <ProtectedRoute path="/pdfprepare" component={PdfPrepare} />

          <ProtectedRoute path="/cancelled" component={Cancelled} />
          <ProtectedRoute path="/successfull" component={Successfull} />
          <ProtectedRoute path="/failed" component={Failed} />

          <ProtectedRoute path="/my-profile" component={MyProfile} />
          <ProtectedRoute path="/change-password1" component={ChangePassword} />

          <ProtectedRoute path="/user-pages-error-404" component={Error404} />
          <ProtectedRoute path="/user-pages-error-500" component={Error500} />
          <ProtectedRoute path="/user-pages-blank-page" component={BlankPage} />
          {/* <Redirect to="/" /> */}
        </Switch>
      </Suspense>
    );
    return <>{AppsRoute}</>;
  }
}

export default AppRoutes;
