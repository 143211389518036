import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import api from "../service/ApiService";
import { baseURL, oauthURL, errorCompute } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
class Sidebar extends Component {
  state = {};

  callOwnProfile = () => {
    api
      .get(baseURL + "/manage-account/my-profile")
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ ownProfile: res.data.data }, () => {
            if (res.data.result.isError === false) {
              if (res.data.data.credentialsExpired === true) {
                localStorage.setItem(
                  "myProfile",
                  JSON.stringify(res.data.data)
                );
                //localStorage.setItem("fullName", res.data.data.fullName);
                //window.location = process.env.PUBLIC_URL + "/change-password1";
              } else {
                localStorage.setItem(
                  "myProfile",
                  JSON.stringify(res.data.data)
                );
                //localStorage.setItem("fullName", res.data.data.fullName);
                //window.location = process.env.PUBLIC_URL + "/dashboardesign";
              }
            } else {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          });
        }
      })
      .catch((err) => errorCompute(err));
  };
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidMount = () => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn === "true") {
      let myProfile = localStorage.getItem("myProfile");
      if (myProfile === undefined || myProfile === null) {
        this.callOwnProfile();
      } else {
        this.setState({ ownProfile: JSON.parse(myProfile) }, () => {});
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      {
        if (this.props.location.hash !== undefined) {
        } else {
          console.log("Calling side bar");
          this.callOwnProfile();
          this.onRouteChanged();
        }
        // let myProfile = localStorage.getItem("myProfile");
        // this.setState({ ownProfile: JSON.parse(myProfile) }, () => {});
      } //else {
      // this.callOwnProfile();
      // }
      // let myProfile = localStorage.getItem("myProfile");
      // if (myProfile === undefined || myProfile === null) {
      //   this.callOwnProfile();
      // } else {
      //   this.setState({ ownProfile: JSON.parse(myProfile) }, () => {});
      // }
      //this.onRouteChanged();
    }
  }

  getIconMapper = (url) => {
    let urlMapperJson = {
      "/dashboard": "mdi mdi-view-dashboard menu-icon",
      "/signtask": "mdi mdi-pen menu-icon",
      "/new-self-sign": "mdi mdi-pencil-box menu-icon",
      "/new-sign": "mdi mdi-pencil-box-outline menu-icon",
      "/pending-sign-list": "mdi mdi-calendar-clock menu-icon",
      "/completed-sign-list": "mdi mdi-check-circle-outline menu-icon",
      "/rejected-sign-list": "mdi mdi-comment-remove-outline menu-icon",
      "/my-documents": "mdi mdi-archive menu-icon",
      "/formtaks": "mdi mdi-file-document-box menu-icon",
      "/new-form-sign": "mdi mdi-comment-multiple-outline menu-icon",
      "/self-form-sign": "mdi mdi-comment-outline menu-icon",
      "/user-list": "mdi mdi-account-multiple menu-icon",
      "/packagemanagement": "mdi mdi-briefcase menu-icon",
      "/package-management": "mdi mdi-briefcase menu-icon",
      "/storagemanagement": "mdi mdi-store menu-icon",
      "/storage-management": "mdi mdi-store menu-icon",
      "/my-storage": "mdi mdi-harddisk menu-icon",
      "/my-signature": "mdi mdi-marker menu-icon",
      "/availablestorage-package": "mdi mdi-cart-outline menu-icon",
      "/storage-balance-request": "mdi mdi-timetable menu-icon",
      "/storage-package-list": "mdi mdi-format-list-bulleted menu-icon",
      "/user-management": "mdi mdi-account-multiple menu-icon",
      "/my-profile": "mdi mdi-face-profile menu-icon",
      "/my-package": "mdi mdi-package-variant menu-icon",
      "/change-password1": "mdi mdi-key menu-icon",
      "/signing-signing-status": "fa fa-clock-o menu-icon",
      "/signing-completed-sign": "fa fa-check-square-o menu-icon",
      "/signing-rejected-sign": "fa fa fa-times-rectangle-o menu-icon",
    };
    return urlMapperJson[url];
  };

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/storagemanagement", state: "storageMenuOpen" },
      { path: "/signtask", state: "signtaskMenuOpen" },
      { path: "/formtaks", state: "formtaksMenuOpen" },
      { path: "/packagemanagement", state: "packagemanagementMenuOpen" },
      { path: "/systemadmin", state: "systemadminMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/signing", state: "signingPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <Link className="sidebar-brand brand-logo" to="/dashboard">
            <img
              className="align-self-center"
              src={`${process.env.PUBLIC_URL}/logo-side.png`}
              alt="logo"
            />
          </Link>
          <Link className="sidebar-brand brand-logo-mini pt-3" to="/dashboard">
            <img src={`${process.env.PUBLIC_URL}/mini-logo.png`} alt="logo" />
          </Link>
        </div>
        <ul className="nav m-t-70">
          {/* <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i
                className={
                  this.getIconMapper("/dashboard") !== undefined
                    ? this.getIconMapper("/dashboard")
                    : "fa fa-plus menu-icon"
                }
              ></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/user-list") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/user-list">
              <i
                className={
                  this.getIconMapper("/dashboard") !== undefined
                    ? this.getIconMapper("/dashboard")
                    : "fa fa-plus menu-icon"
                }
              ></i>
              <span className="menu-title">User Management</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/package-manage")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/package-manage">
              <i
                className={
                  this.getIconMapper("/dashboard") !== undefined
                    ? this.getIconMapper("/dashboard")
                    : "fa fa-plus menu-icon"
                }
              ></i>
              <span className="menu-title">Package Management</span>
            </Link>
          </li> */}

          {/* <li
            className={
              this.isPathActive("/admin") ? "nav-item active" : "nav-item"
            }
          > */}
          {/* <div
              className={
                this.state.adminUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("adminUiMenuOpen")}
              data-toggle="collapse"
            >
              <i className="mdi mdi-grid menu-icon"></i>
              <span className="menu-title">
                <Trans>Management</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.adminUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/admin/dashboard")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/dashboard"
                  >
                    <i className="mdi mdi-view-dashboard menu-icon"> </i>
                    <Trans>Dashboard</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse> */}
          {/* </li> */}
          {/* {this.state.ownProfile !== undefined &&
            this.state.ownProfile.allowedFeatureList !== undefined &&
            this.state.ownProfile.allowedFeatureList.map((e) => {
              if (e.subFeatureList === null) {
                console.log(e.subFeatureList);
                return (
                  <li
                    className={
                      this.isPathActive(e.feature.protectedUrl)
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link className="nav-link" to={e.feature.protectedUrl}>
                      <i
                        className={
                          this.getIconMapper(e.feature.protectedUrl) !==
                          undefined
                            ? this.getIconMapper(e.feature.protectedUrl)
                            : "fa fa-plus menu-icon"
                        }
                      ></i>
                      <span className="menu-title">{e.feature.title}</span>
                    </Link>
                  </li>
                );
              } else {
                return <li>Abu</li>;
              }
            })} */}

          {this.state.ownProfile !== undefined &&
            this.state.ownProfile.allowedFeatureList !== undefined &&
            this.state.ownProfile.allowedFeatureList.map((e) => {
              if (e.subFeatureList !== undefined && e.subFeatureList !== null) {
                return (
                  <>
                    {" "}
                    <li
                      className={
                        this.isPathActive("/" + e.feature.logo)
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <div
                        className={
                          this.state["/" + e.feature.logo]
                            ? "nav-link menu-expanded"
                            : "nav-link"
                        }
                        onClick={() =>
                          this.setState({
                            ["/" + e.feature.logo]:
                              !this.state["/" + e.feature.logo],
                          })
                        }
                        data-toggle="collapse"
                      >
                        <i
                          className={
                            e.feature.logo !== undefined
                              ? "mdi " + e.feature.logo + " menu-icon"
                              : "mdi mdi-grid menu-icon"
                          }
                        ></i>
                        <span className="menu-title">
                          <Trans>{e.feature.title}</Trans>
                        </span>
                        <i className="mdi mdi-chevron-right ml-2"></i>
                      </div>
                      <Collapse in={this.state["/" + e.feature.logo]}>
                        <ul className="nav flex-column sub-menu">
                          {e.subFeatureList.map((sub, i) => {
                            return (
                              <li className="nav-item">
                                {" "}
                                <Link
                                  className={
                                    this.isPathActive(sub.feature.protectedUrl)
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  to={sub.feature.protectedUrl}
                                >
                                  <i
                                    className={
                                      this.getIconMapper(
                                        sub.feature.protectedUrl
                                      ) !== undefined
                                        ? this.getIconMapper(
                                            sub.feature.protectedUrl
                                          )
                                        : "fa fa-plus menu-icon"
                                    }
                                  >
                                    {" "}
                                  </i>
                                  <Trans>{sub.feature.title}</Trans>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  </>
                );
              } else {
                return (
                  <li
                    className={
                      this.isPathActive(e.feature.protectedUrl)
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link className="nav-link" to={e.feature.protectedUrl}>
                      <i
                        className={
                          this.getIconMapper(e.feature.protectedUrl) !==
                          undefined
                            ? this.getIconMapper(e.feature.protectedUrl)
                            : "fa fa-plus menu-icon"
                        }
                      ></i>
                      <span className="menu-title">{e.feature.title}</span>
                    </Link>
                  </li>
                );
              }
            })}

          {/* <li
            className={
              this.isPathActive("/signing-signing-status")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/signing-signing-status">
              <i className="fa fa-clock-o menu-icon"></i>
              <span className="menu-title">Pending Sign</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/signing-completed-sign")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/signing-completed-sign">
              <i className="fa fa-check-square-o menu-icon"></i>
              <span className="menu-title">Completed Sign</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/signing-rejected-sign")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/signing-rejected-sign">
              <i className="fa fa fa-times-rectangle-o menu-icon"></i>
              <span className="menu-title">Rejected Sign</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/my-signature")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/my-signature">
              <i className="fa fa fa-paper-plane-o menu-icon"></i>
              <span className="menu-title">My Signatures</span>
            </Link>
          </li> */}
          {/* <li
            className={
              this.isPathActive("/registration")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/admin-registration">
              <i className="fa fa-suitcase menu-icon"></i>
              <span className="menu-title">Registration</span>
            </Link>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);

